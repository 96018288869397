@tailwind base;
@tailwind components;
@tailwind utilities;

.psv-navbar,
.psv-loader-container {
  display: none !important;
  position: absolute !important;
  height: 0 !important;
  width: 0 !important;
}
.psv-canvas-container {
  cursor:
    /* url('../public/icons/Cursor.svg') 56 56, */
    url('../public/icons/Cursor.svg') 28 28,
    move !important;
}

@layer base {
  @font-face {
    font-family: 'Source Sans Pro';
    src: url('/fonts/SourceSansPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url('/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
  }

  body {
    @apply antialiased;
    padding-top: 0;
    padding-top: env(safe-area-inset-top, 0);
    overscroll-behavior: none;
  }

  p {
    @apply mb-4;
  }

  p:last-child {
    @apply mb-0;
  }

  p a,
  .text-link {
    @apply font-sans font-bold text-blue hover:underline-offset-4 hover:underline hover:decoration-current hover:decoration-2 cursor-pointer;
  }

  .tooltip a,
  .tooltip .text-link {
    @apply text-inherit !text-white;
  }

  ul {
    @apply list-disc ml-[1em];
  }

  ol {
    @apply list-decimal ml-[1em];
  }

  .h-screen-ios {
    height: 100vh;
    height: calc(
      100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
    );
  }

  .min-h-screen-ios {
    min-height: 100vh;
    min-height: calc(
      100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
    );
  }

  .small-caps {
    font-variant: small-caps;
    text-transform: lowercase;
  }

  .vimeo-player > div:first-child {
    height: 100%;
  }

  .vimeo-player iframe {
    position: relative;
    width: 100% !important;
    height: 100% !important;
  }

  /* .custom-drag-cursor {
    cursor:
      url('../public/icons/Cursor.svg') 56 56,
      pointer;
  } */

  .onboarding-parent-active {
    /* @apply outline outline-4 outline-offset-8 outline-white z-20; */
    @apply z-20;
  }

  .onboarding-is-active button:not(.onboarding-parent-active) {
    /* .onboarding-is-active #recipe-drawer:not(.onboarding-parent-active) { */
    /* @apply opacity-20; */
  }
  /*
  .onboarding-is-active > button,
  .onboarding-current > button {
    @apply !opacity-100;
  } */

  .psv-tooltip.custom-tooltip {
    @apply bg-white p-5 max-w-[400px] rounded-md shadow-none;
  }
  .custom-tooltip .psv-tooltip-content {
    @apply text-black p-0 text-sm leading-[1.2] flex flex-col gap-4;
    text-shadow: none;
  }
  .custom-tooltip .psv-tooltip-arrow {
    @apply !hidden;
  }
  .custom-tooltip__content * {
    @apply font-sans;
  }

  .custom-tooltip__content {
    @apply flex flex-col gap-0 p-0;
  }
  .custom-tooltip__content h4 {
    @apply text-medium md:text-medium2 !leading-[1.025] text-grey-800 font-bold small-caps mb-2;
  }
  .custom-tooltip__content p {
    @apply text-grey-900 font-normal text-sm;
  }

  .onboarding-is-active .fixed.z-\[20\] button,
  .onboarding-is-active #recipe-drawer {
    @apply pointer-events-none;
  }

  .text-content p {
    @apply font-serif text-medium2;
  }
}
