*,
:after,
:before {
  box-sizing: border-box;
}

.cookie__popup__settings__content__body__controls__panel__headline,
.cookie__popup__settings__content__head__headline {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: transparent;
  color: #0049b0;
  text-decoration: none;
}

a:hover {
  color: #002964;
  text-decoration: underline;
}

img {
  border-style: none;
  vertical-align: middle;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

button {
  -webkit-appearance: button;
}

input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

.cookie__popup__settings__content__body__controls__panel__headline,
.cookie__popup__settings__content__head__headline,
h2,
h3 {
  color: inherit;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2rem;
}

.cookie__popup__settings__content__head__headline,
h3 {
  font-size: 1.75rem;
}

.cookie__popup__settings__content__body__controls__panel__headline,
.cookie__popup__settings__content__head__headline,
h3 {
  font-size: 1.5rem;
}

.btn {
  border: 2px solid transparent;
  border-radius: 99rem;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 1rem 2rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  user-select: none;
  vertical-align: middle;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 73, 176, 0.25);
  outline: 0;
}

.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active {
  background-image: none;
}

.btn-primary {
  background-color: #0049b0;
  border-color: #0049b0;
  color: #fff;
}

.btn-primary:hover {
  background-color: #00398a;
  border-color: #00347d;
  color: #fff;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 73, 176, 0.5);
}

.btn-primary:disabled {
  background-color: #0049b0;
  border-color: #0049b0;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #00347d;
  border-color: #002f70;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 73, 176, 0.5);
}

.btn-secondary {
  background-color: #8a94a7;
  border-color: #8a94a7;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #748097;
  border-color: #6d7991;
  color: #fff;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 148, 167, 0.5);
}

.btn-secondary:disabled {
  background-color: #8a94a7;
  border-color: #8a94a7;
  color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #6d7991;
  border-color: #68738a;
  color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(138, 148, 167, 0.5);
}

.btn-sm-new {
  border-radius: 1rem;
  font-size: 11.2px;
  line-height: 1.5;
  padding: 0.5rem 0.7rem;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.far {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fa-angle-down:before {
  content: 'ï„‡';
}

.far {
  font-weight: 400;
}

.far {
  font-family: Font Awesome\5 Pro;
}

.cookie__popup__settings__content__body__controls__panel__headline,
.cookie__popup__settings__content__head__headline,
h3 {
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
}

.cookie__popup__settings__content__head__headline,
h3 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0.5rem;
  text-transform: none;
}

.cookie__popup__settings__content__body__controls__panel__headline,
.cookie__popup__settings__content__head__headline,
h2,
h3 {
  font-family:
    Source Sans Pro,
    sans-serif;
}

.cookie__popup__settings {
  backdrop-filter: blur(8px);
  background-color: hsla(0, 0%, 100%, 0.55);
  display: none;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  transition: opacity 0.4s ease-in-out;
  width: 100%;
  z-index: 99998;
  top: 0;
  left: 0;
}

.cookie__popup__settings:before {
  content: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='rgba(0, 0, 0, 0.6)' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E");
  cursor: pointer;
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
}

.cookie__popup__settings.in {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.4s ease-in-out;
}

.cookie__popup__settings .btn {
  box-shadow: none;
  letter-spacing: 0.2rem;
}

.cookie__popup__settings .btn:active,
.cookie__popup__settings .btn:focus,
.cookie__popup__settings .btn:hover {
  box-shadow: none;
}

@media (max-width: 767.98px) {
  .cookie__popup__settings {
    align-items: flex-start !important;
  }

  .cookie__popup__settings .btn {
    display: none;
  }
}

.cookie__popup__settings {
  z-index: 99999;
}

.cookie__popup__settings:before {
  content: '' !important;
}

.btn {
  font-family:
    Source Sans Pro,
    sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.cookie__popup__settings__content {
  -ms-overflow-style: none;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 2px #aaa;
  margin: 20px;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 30px;
  scrollbar-width: none;
  width: 35rem;
}

.cookie__popup__settings__content::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767.98px) {
  .cookie__popup__settings__content {
    width: 100%;
  }
}

.cookie__popup__settings__content .btn {
  border-radius: 5rem;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
}

@media (max-width: 767.98px) {
  .cookie__popup__settings__content .btn {
    display: inline-block !important;
  }
}

.cookie__popup__settings__content__head {
  margin-bottom: 2rem;
  text-align: center;
}

.cookie__popup__settings__content__head__description {
  font-size: 0.85rem;
}

.cookie__popup__settings__content__body__controls {
  margin-bottom: 2rem;
}

.cookie__popup__settings__content__body__controls__panel {
  border-bottom: 2px solid #e6e8ee;
  float: left;
  margin: 0 0 0.75rem;
  padding: 0 0 0.75rem;
  width: 100%;
}

.cookie__popup__settings__content__body__controls__panel:last-of-type {
  border-bottom: none;
  margin-bottom: 2rem;
}

.cookie__popup__settings__content__body__controls__panel__button {
  all: unset;
  background: transparent;
  text-align: left;
  width: 100%;
}

.cookie__popup__settings__content__body__controls__panel__button:focus,
.cookie__popup__settings__content__body__controls__panel__button:hover {
  cursor: pointer;
  outline: revert;
}

.cookie__popup__settings__content__body__controls__panel__headline {
  align-items: center;
  display: flex;
  font-size: 1.075rem;
  justify-content: space-between;
  letter-spacing: 0;
  margin-bottom: 0;
  text-transform: none;
  width: 100%;
}

.cookie__popup__settings__content__body__controls__panel__headline__title {
  padding: 1rem 0;
  width: 80%;
}

@media (max-width: 767.98px) {
  .cookie__popup__settings__content__body__controls__panel__headline__title {
    width: auto;
  }
}

.cookie__popup__settings__content__body__controls__panel__description {
  display: none;
  float: left;
  font-size: 0.85rem;
  margin-bottom: 0;
  width: 80%;
}

.cookie__popup__settings__content__body__controls__panel .switch {
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 1rem;
}

.cookie__popup__settings__content__body__controls__panel .far {
  color: #b9bfcf;
  font-size: 40px;
}

.cookie__popup__settings__content__footer {
  text-align: center;
}

.cookie__popup__settings__content__footer button {
  margin: auto 0.25rem 0.5rem;
}

@media (max-width: 767.98px) {
  .cookie__popup__settings__content__footer {
    -ms-overflow-style: none;
    float: left;
    max-height: 480px;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 100%;
  }

  .cookie__popup__settings__content__footer::-webkit-scrollbar {
    display: none;
  }
}

.cookie__popup__settings .switch {
  display: inline-block;
  height: 28px;
  position: relative;
  width: 50px;
}

@media (max-width: 767.98px) {
  .cookie__popup__settings .switch {
    height: 24px;
    width: 46px;
  }
}

.cookie__popup__settings .switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.cookie__popup__settings .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.cookie__popup__settings .slider:before {
  background-color: #fff;
  bottom: 3px;
  content: '';
  height: 22px;
  left: 4px;
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 22px;
}

@media (max-width: 767.98px) {
  .cookie__popup__settings .slider:before {
    height: 18px;
    width: 18px;
  }
}

.cookie__popup__settings input:checked + .slider {
  background-color: #0049b0;
}

.cookie__popup__settings input:focus + .slider {
  box-shadow: 0 0 1px #0049b0;
}

.cookie__popup__settings input:disabled + .slider {
  background-color: #7db3ff;
}

.cookie__popup__settings input:disabled + .slider:hover {
  cursor: not-allowed;
}

.cookie__popup__settings input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.cookie__popup__settings .slider.round {
  border-radius: 34px;
}

.cookie__popup__settings .slider.round:before {
  border-radius: 50%;
}

.cookie__popup__settings__content__body__controls__panel__arrow {
  display: block;
  position: relative;
  height: 2rem;
  width: 1rem;
}
.cookie__popup__settings__content__body__controls__panel__arrow::before {
  content: '';
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-left: 2px solid #ccc;
  border-top: 2px solid #ccc;
}
.cookie__popup__settings__content__body__controls__panel__arrow.fa-angle-down::before {
  transform: rotate(-135deg);
  top: 5px;
}
.cookie__popup__settings__content__body__controls__panel__arrow.fa-angle-up::before {
  transform: rotate(45deg);
  top: 13px;
}

/*! CSS Used fontfaces */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url('/cookiepopup/43706a2883eb57c79284.ttf') format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
